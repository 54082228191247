import React, { useState, useRef, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer } from '@react-google-maps/api';
import { filter, initial } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  TextField,
  Snackbar,
  Alert
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { ChakraProvider, theme } from '@chakra-ui/react'
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import CustomMap from '../components/CustomMap';
import GeofenceMap from '../components/GeofenceMap';
import AddNewShippment from '../components/AddNewShippment';
// mock
import USERLIST from '../_mock/user';
// import truckIcon from '../assets/img/delivery-truck.png'
// import thermo from '../assets/img/thermometer.png'
import truckIcon from '../assets/img/container.svg'
import thermo from '../assets/img/thermometer.png'
import upGreen from '../assets/img/up-arrow-green.svg'
import violationsIcon from '../assets/img/violations.svg'
import doorOpenIcon from '../assets/img/doorOpen.svg'
import batteryRedIcon from '../assets/img/battery-red.svg'
// ----------------------------------------------------------------------

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: -3.745,
  lng: -38.523
};

const TABLE_HEAD = [
  { id: 'name', label: '', alignRight: false },
  // { id: 'company', label: 'Company', alignRight: false },
  // { id: 'role', label: 'Role', alignRight: false },
  // { id: 'isVerified', label: 'Verified', alignRight: false },
  // { id: 'status', label: 'Status', alignRight: false },
];
const initialState = {
  Name: "",
  Coordinates: "",
  address: ''
}
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.GeofenceId.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const snackbarinit = {
  open: false,
  message: '',
  type: ''
}

export default function Geofence() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');
  const [formData, setFormData] = useState(initialState);

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [selectedState, setSelectedState] = useState(0);
  const [directions, setDirections] = useState([]);
  const [showHistory, setShowHistory] = useState('');
  const [historyLoading, setHistoryLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [allShipmentsData, setAllShipmentsData] = useState([]);
  const [geofenceList, setGeofenceList] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [activeIndex, setActiveIndex] = useState('');
  const [libraries, setLibraries] = useState(['places']);
  const [createNew, setCreateNew] = useState(false);
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [snackbarData, setSnackbarData] = useState(snackbarinit);
  const [loading, setLoading] = useState(false);
  const [selectedCoordinagtes, setSelectedCoordinates] = useState([]);
  const [showGeoFence, setShowGeofence] = useState(false);
  console.log("selectedCoordinagtes", selectedCoordinagtes)


  const cancel = () => {
    setFormData(initialState);
    setCreateNew(false);
    setLoading(false);
  }

  const { isLoaded } = useJsApiLoader({
    // id: 'google-map-script',
    // googleMapsApiKey: "AIzaSyCnhDNnG9WqjXYoiRYRbGIedF6PKEiBFRg",
    googleMapsApiKey: "AIzaSyBPVP4bF84FZ_qZkqhWTdpMO4_2kGv-Tl4",
    libraries
  })

  const [map, setMap] = React.useState(/** @type google.maps.Map */(null));

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds(center);
  //   map.fitBounds(bounds);
  //   setMap(map)
  // }, [])

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null)
  // }, [])

  const onLoad = React.useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, []);

  const onUnmount = React.useCallback((map) => {
    setMap(/** @type google.maps.Map */(null));
  }, []);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (selected.length === geofenceList.length) {
      setSelectedState(selectedState + 1);
      setSelected([]);
    } else {
      if (event.target.checked) {
        const newSelecteds = geofenceList.map((n) => n.GeofenceName);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    }
  };

  const handleClick = (event, id, coordinates) => {
    console.log("GeofenceId", id)
    const selectedIndex = selected.indexOf(id);
    // let newSelected = [];
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, GeofenceId);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    // }
    if (id === selected) {
      setSelected("");
      setSelectedState(0);
      setSelectedCoordinates([]);
      setShowGeofence(false);
    } else {
      setSelected(id);
      setSelectedState(selectedState + 1);
      setSelectedCoordinates(coordinates);
      setShowGeofence(true);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - geofenceList.length) : 0;

  const filteredUsers = applySortFilter(geofenceList, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  const getAllGeofence = () => {
    setDataLoading(true);
    axios.post('https://amizaya.com/AmizayaAPI/api/Tracking/GetGeofenceDetails')
      .then(({ data }) => {
        setDataLoading(false);
        setGeofenceList(data);
        console.log("data", data)
      }).catch((error) => {
        console.log(error);
        setDataLoading(false);
      })
  }

  useEffect(() => {
    getAllGeofence();
  }, [])

  const saveGeofence = () => {
    console.log("formdata", formData)
    setLoading(true);
    console.log("formdata", formData)
    axios.post('https://amizaya.com/AmizayaAPI/api/Tracking/CreateGeoFence', {
      Name: formData?.Name,
      Coordinates: formData?.Coordinates,
    })
      .then(({ data }) => {
        setLoading(false);
        // setFormData(data);
        console.log("data", data)
        if (data && data.Status.toLowerCase() === 'ok') {
          setSnackbarData({
            type: 'success',
            message: 'Added successfully',
            open: true
          });
          cancel();
          getAllGeofence();
          setDirectionsResponse(null);
        } else {
          setSnackbarData({
            type: 'error',
            message: data?.Status,
            open: true
          })
        }
      }).catch((error) => {
        console.log(error);
        setSnackbarData({
          type: 'error',
          message: 'Failed to add',
          open: true
        })
        setLoading(false);
      })
  }

  return (
    <Page title="Tracking">
      <Box paddingX={3}>
        <Snackbar open={snackbarData.open} autoHideDuration={6000} onClose={() => setSnackbarData(snackbarinit)}>
          <Alert elevation={6} variant="filled" onClose={() => setSnackbarData(snackbarinit)} severity={snackbarData.type} sx={{ width: '100%' }}>
            {snackbarData.message}
          </Alert >
        </Snackbar>
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Tracking
          </Typography>
          <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button>
        </Stack> */}
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {createNew ? "Create a Geofence" : "Geofence"}
          </Typography>
          {createNew ? <Button variant="contained" disabled={loading} startIcon={createNew ? "" : <Iconify icon="eva:plus-fill" />} onClick={() => { setCreateNew(!createNew); setDirectionsResponse(null); setSelected([]) }}>
            Cancel
          </Button> :
            <Button variant="contained" disabled={loading} startIcon={createNew ? "" : <Iconify icon="eva:plus-fill" />} onClick={() => { setCreateNew(!createNew); setShowGeofence(false); setDirectionsResponse(null) }}>
              Create a Geofence
            </Button>}
        </Stack>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12} sm={12}>
            <Card style={{ height: '80vh' }}>
              {createNew
                ? <>
                  <Box padding={2}>
                    <Box paddingTop={2}>
                      <TextField label="Name" variant="outlined" fullWidth onChange={(e) => setFormData({ ...formData, Name: e.target.value })} />
                    </Box>
                    <Box paddingTop={2}>
                      <TextField label="Description" variant="outlined" fullWidth onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                    </Box>
                    {formData?.address && <Box paddingTop={2}>
                      {formData?.address}
                    </Box>}
                    <Box paddingTop={4} display={"flex"} alignItems={"center"} justifyContent={"flex-end"}>
                      <Button disabled={loading} onClick={() => {
                        setCreateNew(false);
                        setDirectionsResponse(null);
                      }}>Cancel</Button>
                      <Box width={"16px"} />
                      <Button variant="contained" disabled={(!formData.Name || formData.Coordinates.length === 0) || loading}
                        onClick={() => saveGeofence()}>{loading ? 'Saving...' : 'Save'}</Button>
                    </Box>
                  </Box>
                </>
                : <>
                  {console.log("selected", selected)}
                  <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                  <Scrollbar>
                    {dataLoading ? <Box height={"60vh"} display={"flex"} justifyContent={"center"} alignItems={"center"}>Loading...</Box> :
                      <TableContainer >
                        <Table>
                          <UserListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            // rowCount={geofenceList.length}
                            // numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            // onSelectAllClick={handleSelectAllClick}
                            hideSelectAll
                          />
                          <TableBody>
                            {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                              const { id, Id, name, GeofenceId, GeofenceName, Name, Coordinates
                              } = row;
                              const isItemSelected = selected === Id;

                              return (
                                <TableRow
                                  hover
                                  key={GeofenceId}
                                  tabIndex={-1}
                                  role="checkbox"
                                  selected={isItemSelected}
                                  aria-checked={isItemSelected}
                                >
                                  <TableCell padding="checkbox">
                                    <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, Id, Coordinates)} />
                                  </TableCell>
                                  <TableCell component="th" scope="row" padding="none" onClick={(event) => handleClick(event, Id, Coordinates)} style={{ cursor: "pointer" }}>
                                    <Box paddingY={2} display={"flex"} alignItems={"center"} justifyContent={"space-around"}>
                                      <div style={{ width: "80%" }}>
                                        <Box >
                                          <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
                                            <Typography variant="subtitle2" noWrap>
                                              {/* {name}  */}
                                              {Name}
                                            </Typography>
                                          </Stack>
                                        </Box>
                                      </div>
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>

                          {!dataLoading && <>
                            {isUserNotFound && (
                              <TableBody>
                                <TableRow>
                                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                    <SearchNotFound searchQuery={filterName} />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            )}
                          </>}

                        </Table>
                      </TableContainer>}
                  </Scrollbar>

                  {/* <TablePagination
  rowsPerPageOptions={[5, 10, 25]}
  component="div"
  count={USERLIST.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/> */}
                </>}

            </Card>
          </Grid>
          <Grid item md={8} xs={12} sm={12} >
            <Card style={{ height: '80vh' }} >
              <ChakraProvider theme={theme}>
                <GeofenceMap
                  directionsResponse={directionsResponse}
                  setDirectionsResponse={setDirectionsResponse}
                  setCreateNew={setCreateNew}
                  createNew={createNew}
                  setFormData={setFormData}
                  formData={formData}
                  selectedCoordinagtes={selectedCoordinagtes}
                  showGeoFence={showGeoFence}
                  setShowGeofence={setShowGeofence}
                />
              </ChakraProvider>
            </Card>
          </Grid>
        </Grid>

      </Box>
    </Page >
  );
}
