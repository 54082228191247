import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Input,
  SkeletonText,
  Text,
} from '@chakra-ui/react'
import { FaLocationArrow, FaTimes } from 'react-icons/fa'

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from '@react-google-maps/api'
import { useRef, useState } from 'react'

const center = { lat: 25.3548, lng: 51.1839 }

function SelectionMap({ setOpenMap, setFormData, formData }) {
  const [libraries, setLibraries] = useState(['places']);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBPVP4bF84FZ_qZkqhWTdpMO4_2kGv-Tl4',
    libraries,
  })

  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  const [directionsResponse, setDirectionsResponse] = useState(null)
  const [distance, setDistance] = useState('')
  const [duration, setDuration] = useState('')

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef()
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef()

  if (!isLoaded) {
    return <SkeletonText />
  }

  async function calculateRoute() {
    console.log("originRef.current", originRef.current.value)
    if (originRef.current.value === '' || destiantionRef.current.value === '') {
      return
    }
    // eslint-disable-next-line no-undef
    const directionsService = new google.maps.DirectionsService()
    const results = await directionsService.route({
      origin: originRef.current.value,
      destination: destiantionRef.current.value,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    })
    console.log("results", results.routes[0]?.overview_path.map((l) => ({
      lat: l.lat(),
      lng: l.lng()
    })))
    console.log("results", results)
    setDirectionsResponse(results)
    setDistance(results.routes[0].legs[0].distance.text)
    setDuration(results.routes[0].legs[0].duration.text)
  }

  function clearRoute() {
    setOpenMap('')
    setDirectionsResponse(null)
    setDistance('')
    setDuration('')
    originRef.current.value = ''
    destiantionRef.current.value = ''
  }
  console.log("directionsResponse", directionsResponse?.routes[0]?.legs[0]?.start_location?.lat())

  const confirmLocation = () => {
    console.log("directionsResponse", directionsResponse?.routes[0]?.legs[0])
    const legs = directionsResponse?.routes[0]?.legs[0];
    const data = {
      origin: {
        label: legs?.start_address,
        location: {
          lat: legs?.start_location?.lat(),
          lng: legs?.start_location?.lng()
        }
      },
      destination: {
        label: legs?.end_address,
        location: {
          lat: legs?.end_location?.lat(),
          lng: legs?.end_location?.lng()
        }
      },
    }
    console.log("data", data)
    setFormData({ ...formData, origin: data.origin, destination: data.destination, Duartion: duration, Distance: distance })
    setTimeout(() => {
      clearRoute();
    }, 500);
  }

  return (
    <Flex
      position='relative'
      flexDirection='column'
      alignItems='center'
      h='100vh'
      w='100%'
    >
      <Box position='absolute' left={0} top={0} h='100%' w='100%'>
        {/* Google Map Box */}
        <GoogleMap
          center={center}
          zoom={15}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          options={{
            // zoomControl: false,
            streetViewControl: false,
            // mapTypeControl: false,
            fullscreenControl: false,
          }}
          onLoad={map => setMap(map)}
        >
          {console.log("directionsResponse", directionsResponse)}
          <Marker position={center} />
          {directionsResponse && (
            <DirectionsRenderer directions={directionsResponse} />
          )}
        </GoogleMap>
      </Box>
      <Box
        p={4}
        borderRadius='lg'
        m={4}
        bgColor='white'
        shadow='base'
        minW='container.md'
        zIndex='1'
      >
        <HStack spacing={2} justifyContent='space-between'>
          <Box flexGrow={1}>
            <Autocomplete>
              <Input type='text' placeholder='Origin' ref={originRef} />
            </Autocomplete>
          </Box>
          <Box flexGrow={1}>
            <Autocomplete>
              <Input
                type='text'
                placeholder='Destination'
                ref={destiantionRef}
              />
            </Autocomplete>
          </Box>

          <ButtonGroup>
            <Button type='submit' onClick={() => calculateRoute()}>
              Get Route
            </Button>
            <Button disabled={!directionsResponse} onClick={confirmLocation}>Confirm</Button>
            <IconButton
              aria-label='center back'
              icon={<FaTimes />}
              onClick={() => clearRoute()}
            />
          </ButtonGroup>
        </HStack>
        <HStack spacing={4} mt={4} justifyContent='space-between'>
          <Text>Distance: {distance} </Text>
          <Text>Duration: {duration} </Text>
          <IconButton
            aria-label='center back'
            icon={<FaLocationArrow />}
            isRound
            onClick={() => {
              map.panTo(center)
              map.setZoom(10)
            }}
          />
        </HStack>
      </Box>
    </Flex>
  )
}

export default SelectionMap
