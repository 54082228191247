import React, { useState, useRef, useEffect } from 'react';
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer } from '@react-google-maps/api';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Grid,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import Geocode from "react-geocode";
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import CustomMap from '../components/CustomMap';
import AddNewShippment from '../components/AddNewShippment';
// mock
import USERLIST from '../_mock/user';
// import truckIcon from '../assets/img/delivery-truck.png'
import truckIcon from '../assets/img/container.svg'
import thermo from '../assets/img/thermometer.png'
import upGreen from '../assets/img/up-arrow-green.svg'
import violationsIcon from '../assets/img/violations.svg'
import doorOpenIcon from '../assets/img/doorOpen.svg'
import batteryRedIcon from '../assets/img/battery-red.svg'
import batteryFullIcon from '../assets/img/batteryfull.svg'
import batteryHalfIcon from '../assets/img/batteryhalf.svg'
import batteryRedIcon2 from '../assets/img/batteryred2.svg'
// ----------------------------------------------------------------------

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: -3.745,
  lng: -38.523
};

const TABLE_HEAD = [
  { id: 'name', label: '', alignRight: false },
  // { id: 'company', label: 'Company', alignRight: false },
  // { id: 'role', label: 'Role', alignRight: false },
  // { id: 'isVerified', label: 'Verified', alignRight: false },
  // { id: 'status', label: 'Status', alignRight: false },
  // { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.Name?.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Tracking() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [selectedState, setSelectedState] = useState(0);
  const [directions, setDirections] = useState([]);
  const [showHistory, setShowHistory] = useState('');
  const [historyLoading, setHistoryLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [allShipmentsData, setAllShipmentsData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [activeIndex, setActiveIndex] = useState('');
  const [showCenter, setShowCenter] = useState('');
  const [libraries, setLibraries] = useState(['places']);
  const [addressList, setAddressList] = useState([]);

  const { isLoaded } = useJsApiLoader({
    // id: 'google-map-script',
    // googleMapsApiKey: "AIzaSyCnhDNnG9WqjXYoiRYRbGIedF6PKEiBFRg",
    googleMapsApiKey: "AIzaSyBPVP4bF84FZ_qZkqhWTdpMO4_2kGv-Tl4",
    libraries
  })

  const [map, setMap] = React.useState(/** @type google.maps.Map */(null));


  const getAddress = async (location, index) => {
    Geocode.setApiKey("AIzaSyBPVP4bF84FZ_qZkqhWTdpMO4_2kGv-Tl4");
    await Geocode.fromLatLng(location.lat, location.lng).then(
      response => {
        const addressString = { address: response.results[0].formatted_address, index };
        // setAddress(addressString)
        console.log("addressString", addressString)
        setAddressList(addressList => [...addressList, addressString])
      },
      error => {
        console.error(error);
      }
    );
  }

  const getHistoryAction = (id) => {
    setHistoryLoading(true);
    const formdata = new FormData();
    formdata.append("tracker_id", id);

    const requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };

    fetch("https://amizaya.com/AmizayaAPI/api/Tracking/GetVendorShipmentEvent", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log("result", result);
        setHistoryLoading(false);
        setHistoryData(result);
        result.map((item, index) => getAddress(item.localization.location, index))
      })
      .catch(error => {
        console.log('error', error)
        setHistoryLoading(false);
      });
    // axios.post('https://amizaya.com/AmizayaAPI/api/Tracking/GetShipmentHistory', {
    //   ShipmentId: id
    // })
    //   .then(({ data }) => {
    //     setHistoryLoading(false);
    //     setHistoryData(data);
    //     console.log("data", data)
    //   }).catch((error) => {
    //     console.log(error);
    //     setHistoryLoading(false);
    //   })
  }

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds(center);
  //   map.fitBounds(bounds);
  //   setMap(map)
  // }, [])

  // const onUnmount = React.useCallback(function callback(map) {
  //   setMap(null)
  // }, [])

  const onLoad = React.useCallback((map) => {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map)
  }, []);

  const onUnmount = React.useCallback((map) => {
    setMap(/** @type google.maps.Map */(null));
  }, []);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (selected.length === allShipmentsData.length) {
      setSelectedState(selectedState + 1);
      setSelected([]);
    } else {
      if (event.target.checked) {
        const newSelecteds = allShipmentsData.map((n) => n.ContainerId);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    }
  };

  const handleClick = (event, ContainerId) => {
    console.log("name", ContainerId)
    const selectedIndex = selected.indexOf(ContainerId);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, ContainerId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
    setSelectedState(selectedState + 1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allShipmentsData.length) : 0;

  const filteredUsers = applySortFilter(allShipmentsData, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  React.useEffect(() => {
    console.log("selectedState", selectedState)
    if (selected.length === 0 && selectedState === 0) {
      const _select = filteredUsers.map(l => l.ContainerId)
      setSelected(_select)
    }
  }, [filteredUsers, selected, selectedState])

  const getAllShipmentsRecords = () => {
    setDataLoading(true);
    axios.get('https://amizaya.com/AmizayaAPI/api/Tracking/GetAllShipments')
      .then(({ data }) => {
        setDataLoading(false);
        setAllShipmentsData(data);
        console.log("data", data)
      }).catch((error) => {
        console.log(error);
        setDataLoading(false);
      })
  }

  useEffect(() => {
    getAllShipmentsRecords();
  }, [])

  function imageLoader(batteryPower) {
    let img = "";
    if (batteryPower >= 50 && batteryPower < 80) {
      img = batteryHalfIcon
    } else if (batteryPower >= 80) {
      img = batteryFullIcon
    } else if (batteryPower < 50 && batteryPower >= 30) {
      img = batteryRedIcon2
    } else if (batteryPower < 20) {
      img = batteryRedIcon
    } else {
      img = batteryRedIcon
    }
    return img
  }

  return (
    <Page title="Tracking">
      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Tracking
          </Typography>
          <Button variant="contained" component={RouterLink} to="#" startIcon={<Iconify icon="eva:plus-fill" />}>
            New User
          </Button>
        </Stack> */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Tracking
        </Typography>
        <AddNewShippment getAllShipmentsRecords={getAllShipmentsRecords} />
      </Stack>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12} sm={12}>
          <Card style={{ height: '80vh' }}>
            {showHistory
              ? <>
                <Box padding={2}>
                  <Stack direction="row" alignItems="center" spacing={1} paddingBottom={1}>
                    <IconButton aria-label="back" onClick={() => { setShowHistory(false); setHistoryData([]); }}>
                      <Iconify icon="eva:arrow-back-outline" />
                    </IconButton>
                    <Typography variant='h6'>{showHistory}</Typography>
                  </Stack>
                  <Box className='track-history-container'>
                    {historyLoading ? <Box height={"60vh"} display={"flex"} justifyContent={"center"} alignItems={"center"}>Loading...</Box> :
                      <>
                        {historyData.length > 0 ? <>
                          <List sx={{ width: '100%', bgcolor: 'background.paper', paddingTop: '0' }}>
                            {historyData.map((item, index) => (
                              <ListItem className='listItemCustom' key={item.timestamp}>
                                <Box width={"25%"}>
                                  <button className='round' onClick={() => setShowCenter(item.localization.location)} onKeyDown={() => setShowCenter(item.localization.location)}>
                                    <span className='showOnhover' />
                                  </button>
                                </Box>

                                {/* {console.log("addressList", addressList)} */}
                                {/* <ListItemAvatar>
                            <Avatar>
                              <Iconify icon="eva:play-circle-outline" style={{ color: activeIndex === index && 'red' }} />
                            </Avatar>
                          </ListItemAvatar> */}
                                {/* <Box width={"16px"} /> */}
                                <Box width={"80%"}>
                                  <ListItemText
                                    primary={`${addressList.find(item => item.index === index)?.address.slice(0, 50)} ${addressList.find(item => item.index === index)?.address.length > 50 ? "..." : ""}`}
                                  // secondary={}
                                  />
                                  <small>{moment.unix(item.timestamp).format('DD-MMM-YYYY LT')}</small>
                                </Box>
                              </ListItem>
                            ))}
                          </List>
                        </> : <Box height={"60vh"} display={"flex"} justifyContent={"center"} alignItems={"center"}>No History To Show</Box>}
                      </>
                    }
                    {/* <Box paddingY={1}></Box> */}

                  </Box>
                </Box>
              </>
              : <>
                <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                <Scrollbar sx={{ height: "70vh" }}>
                  {dataLoading ? <Box height={"60vh"} display={"flex"} justifyContent={"center"} alignItems={"center"}>Loading...</Box> :
                    <TableContainer >
                      <Table>
                        <UserListHead
                          order={order}
                          orderBy={orderBy}
                          headLabel={TABLE_HEAD}
                          rowCount={allShipmentsData.length}
                          numSelected={selected.length}
                          onRequestSort={handleRequestSort}
                          onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                          {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const { id, Id, name, Name, role, status, company, avatarUrl, isVerified, ArabicName, dateTime, temperature,
                              Status, speed, engine, ContainerId, Event, DeviceSerial, OriginPlace, DestinationPlace, EstimatedTimeDepart, EstimatedTimeArrival, index
                            } = row;
                            const isItemSelected = selected.indexOf(ContainerId) !== -1;

                            return (
                              <TableRow
                                hover
                                key={index}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}

                                style={{ cursor: "pointer" }}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, ContainerId)} />
                                </TableCell>
                                <TableCell component="th" scope="row" padding="none" onClick={() => { setShowHistory(Name || ContainerId); getHistoryAction(DeviceSerial) }}>
                                  <Box paddingY={2} display={"flex"} alignItems={"center"} justifyContent={"space-around"}>
                                    <img src={truckIcon} alt='object' style={{ width: '25px' }} />
                                    <div style={{ width: "80%" }}>
                                      <Box paddingBottom={1}>
                                        <Stack direction="row" alignItems="center" spacing={1} justifyContent="space-between">
                                          <Typography variant="subtitle2" noWrap>
                                            {/* {name}  */}
                                            {ArabicName || ContainerId}
                                          </Typography>
                                          <span style={{ background: 'red', width: '20px', height: '10px', borderRadius: "2px" }} />
                                        </Stack>
                                      </Box>
                                      <Box paddingBottom={1} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                                        <Box display={"flex"} >
                                          <Iconify icon="eva:pin-outline" style={{ fontSize: "30px" }} />
                                          <Box width={"4px"} />
                                          <Box>
                                            <Typography variant='body2'>{OriginPlace}</Typography>
                                            <small>{moment(EstimatedTimeDepart && EstimatedTimeDepart).format('DD-MMM-YYYY LT')}</small>
                                          </Box>
                                        </Box>
                                        <Box paddingX={1} display={"flex"} flexDirection={"column"} />
                                        <Box display={"flex"} >
                                          <Iconify icon="eva:pin-outline" style={{ fontSize: "30px" }} />
                                          <Box width={"4px"} />
                                          <Box>
                                            <Typography variant='body2'>{DestinationPlace}</Typography>
                                            <small>{moment(EstimatedTimeArrival && EstimatedTimeArrival).format('DD-MMM-YYYY LT')}</small>
                                          </Box>
                                        </Box>
                                      </Box>
                                      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                        <small style={{ display: 'flex', alignItems: 'center' }}>
                                          <img src={thermo} style={{ width: '20px' }} alt="thermo" />
                                          {/* <Iconify icon="eva:thermometer-outline" /> */}
                                          {Event.temperature || 0} C
                                        </small>
                                        <small>
                                          {Status === "InProgress" && <img src={upGreen} style={{ width: '25px' }} alt="upGreen" />}
                                        </small>
                                        {/* <small>
                                          <img src={violationsIcon} style={{ width: '30px' }} alt="violationsIcon" />
                                        </small> */}
                                        {Event?.type === "OPEN" && <small>
                                          <img src={doorOpenIcon} style={{ width: '25px' }} alt="doorOpenIcon" />
                                        </small>}
                                        <small style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                          <img src={imageLoader(Event?.battery_power)} style={{ width: '25px' }} alt="batteryRedIcon" />
                                          <Box width={"4px"} />
                                          {Event?.battery_power}%
                                        </small>
                                      </Box>
                                      {/* <small>{dateTime}</small> */}
                                    </div>
                                  </Box>
                                </TableCell>
                                {/* <TableCell align="left">{company}</TableCell>
          <TableCell align="left">{role}</TableCell>
          <TableCell align="left">{isVerified ? 'Yes' : 'No'}</TableCell>
          <TableCell align="left">
            <Label variant="ghost" color={(status === 'banned' && 'error') || 'success'}>
              {sentenceCase(status)}
            </Label>
          </TableCell> */}

                                {/* <TableCell align="right">
                                  <UserMoreMenu setShowHistory={setShowHistory} getHistoryAction={getHistoryAction} id={DeviceSerial} Name={ArabicName} />
                                </TableCell> */}
                              </TableRow>
                            );
                          })}
                          {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )}
                        </TableBody>

                        {!dataLoading && <>
                          {isUserNotFound && (
                            <TableBody>
                              <TableRow>
                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                  <SearchNotFound searchQuery={filterName} />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          )}
                        </>}

                      </Table>
                    </TableContainer>
                  }
                </Scrollbar>

                {/* <TablePagination
  rowsPerPageOptions={[5, 10, 25]}
  component="div"
  count={USERLIST.length}
  rowsPerPage={rowsPerPage}
  page={page}
  onPageChange={handleChangePage}
  onRowsPerPageChange={handleChangeRowsPerPage}
/> */}
              </>}

          </Card>
        </Grid>
        <Grid item md={8} xs={12} sm={12} >
          <Card style={{ height: '80vh' }} >
            {(historyLoading) ? <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'80vh'}>
              loading...
            </Box> : <>
              {(selected.length > 0 || showHistory)
                ? <CustomMap
                  selected={selected}
                  setShowHistory={setShowHistory}
                  showHistory={showHistory}
                  historyData={historyData}
                  setActiveIndex={setActiveIndex}
                  selectedList={filteredUsers.filter(l => selected.includes(l.ContainerId))}
                  showCenter={showCenter}
                  getHistoryAction={getHistoryAction}
                  historyLoading={historyLoading}
                // id={filteredUsers.filter(l => selected.includes(l.Name))?.Id}
                // Name={filteredUsers.filter(l => selected.includes(l.Name))?.ArabicName}
                />
                : <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'80vh'}>
                  <Typography variant='h6'>Please select an object to view</Typography>
                </Box>}
            </>}
          </Card>
        </Grid>
      </Grid>
    </Page >
  );
}
