import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

// ----------------------------------------------------------------------
const num = 0
const users = [...Array(10)].map((_, index) => ({
  id: faker.datatype.uuid(),
  avatarUrl: `/static/mock-images/avatars/avatar_${index + 1}.jpg`,
  name: `350${index + 1}`,
  arabicName: 'ب د هـ',
  dateTime: '2022-05-06 17:02:49',
  temperature: '0',
  speed: '0',
  daily: false,
  weekly: false,
  engine: true,
  zones: 0,
  company: faker.company.companyName(),
  isVerified: faker.datatype.boolean(),
  status: sample(['active', 'banned']),
  role: sample([
    'XLS',
    'XLS',
    'XLS',
    'XLS',
    'XLS',
    'XLS',
    'XLS',
    'XLS',
    'XLS',
    'XLS',
  ]),
}));

export default users;
