import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Grid,
  Box,
  Drawer,
  TextField,
  Typography,
  IconButton,
  FormGroup,
  FormControlLabel,
  Snackbar,
  Alert,
  Autocomplete,
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { ChakraProvider, theme } from '@chakra-ui/react'
import axios from 'axios';

import SelectionMap from './SelectionMap';
import navigation from '../assets/img/navigation-2.svg'
import Iconify from './Iconify';


const INIT_STATE = {
  // "settings": {
  //   "tracking": {
  //     "mode": {
  //       "motion": 0,
  //       "idle": 0
  //     }
  //   },
  //   "notifications": {
  //     "emails": {
  //       "enabled": false
  //     }
  //   },
  //   "alarms": {
  //     "temperature": {
  //       "enabled": false,
  //       "min": 0,
  //       "max": 0
  //     },
  //     "idle": {
  //       "enabled": false,
  //       "time": 0,
  //       "distance": 0
  //     },
  //     "battery": {
  //       "enabled": false,
  //       "percentage": 0
  //     },
  //     "arrival": {
  //       "enabled": false,
  //       "meters": 0
  //     },
  //     "light": {
  //       "enabled": false
  //     },
  //     "vessel": {
  //       "enabled": false
  //     }
  //   }
  // },
  // "origin": {
  //   "label": "",
  //   "location": {
  //     "lat": 24.69388,
  //     "lng": 46.72404
  //   }
  // },
  // "destination": {
  //   "label": "",
  //   "location": {
  //     "lat": 24.0996,
  //     "lng": 45.3489
  //   }
  // },
  // "name": "RUH-QUW-001",
  // "description": "Food container",
  // // "vessel": "RUH-06789",
  // "vessel": "",
  // trackers: [
  //   "89201234556895"
  // ]
  // id: "123456",
  containerId: '',
  deviceSerial: '',
  estimatedTimeArrival: new Date(),
  estimatedTimeDepart: new Date(),
  originPlace: '',
  originLat: '',
  originLong: '',
  destinationPlace: '',
  destinationLat: '',
  destinationLong: '',
  name: '',
  nameAR: '',
  description: '',
  // createdBy: '0',
  geofenceId: '',
  Distance: "",
  Duartion: "",
  Priority: ""
}
const snackbarinit = {
  open: false,
  message: '',
  type: ''
}
export default function AddNewShippment({ getAllShipmentsRecords }) {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openMap, setOpenMap] = useState();
  const [formData, setFormData] = useState(INIT_STATE);
  const [enableButton, setEnableButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [snackbarData, setSnackbarData] = useState(snackbarinit);
  const [geofenceList, setGeofenceList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [priorityList, setPriorityList] = useState([]);

  const cancel = () => {
    setFormData(INIT_STATE);
    setOpenDrawer(false);
    setLoading(false);
    setEnableButton(false);
  }

  const getGeofenceList = () => {
    setLoading(true);
    axios.post('https://amizaya.com/AmizayaAPI/api/Tracking/GetGeofenceInfo')
      .then(({ data }) => {
        setLoading(false);
        // setFormData(data);
        console.log("data", data)
        setGeofenceList(data);
      }).catch((error) => {
        console.log(error);
        setSnackbarData({
          type: 'error',
          message: 'Failed to add',
          open: true
        })
        setLoading(false);
      })
  }
  const getDeviceList = () => {
    setLoading(true);
    axios.post('https://amizaya.com/AmizayaAPI/api/Tracking/GetDeviceInfo')
      .then(({ data }) => {
        setLoading(false);
        // setFormData(data);
        console.log("data", data)
        setDeviceList(data);
      }).catch((error) => {
        console.log(error);
        setSnackbarData({
          type: 'error',
          message: 'Failed to add',
          open: true
        })
        setLoading(false);
      })
  }
  const getPriorityList = () => {
    setLoading(true);
    axios.get('https://amizaya.com/AmizayaAPI/api/Tracking/GetPriority')
      .then(({ data }) => {
        setLoading(false);
        // setFormData(data);
        console.log("data", data)
        setPriorityList(data);
      }).catch((error) => {
        console.log(error);
        setSnackbarData({
          type: 'error',
          message: 'Failed to fetch priority list',
          open: true
        })
        setLoading(false);
      })
  }

  useEffect(() => {
    if (formData?.containerId && formData?.description && formData?.origin?.label) {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  }, [formData])

  useEffect(() => {
    getGeofenceList();
    getDeviceList();
    getPriorityList();
  }, [])

  useEffect(() => {
    if (formData?.origin && formData?.destination) {
      setFormData({
        ...formData,
        originLat: formData?.origin?.location?.lat,
        originLong: formData?.origin?.location?.lng,
        originPlace: formData?.origin?.label,
        destinationLat: formData?.destination?.location?.lat,
        destinationLong: formData?.destination?.location?.lng,
        destinationPlace: formData?.destination?.label
      })
    };
  }, [formData?.origin, formData?.destination])


  const saveAction = () => {
    setLoading(true);
    const { destination, origin, name, nameAR, ...rest } = formData
    console.log("payload", rest)
    axios.post('https://amizaya.com/AmizayaAPI/api/Tracking/CreateShipment', rest)
      .then(({ data }) => {
        setLoading(false);
        // setFormData(data);
        console.log("data", data)
        if (data && data.Status.toLowerCase() === 'ok') {
          setSnackbarData({
            type: 'success',
            message: 'Added successfully',
            open: true
          });
          cancel();
          getAllShipmentsRecords();
        } else {
          setSnackbarData({
            type: 'error',
            message: data?.Status,
            open: true
          })
        }
      }).catch((error) => {
        console.log(error);
        setSnackbarData({
          type: 'error',
          message: 'Failed to add',
          open: true
        })
        setLoading(false);
      })
  }

  return (
    <div>
      <Snackbar open={snackbarData.open} autoHideDuration={6000} onClose={() => setSnackbarData(snackbarinit)}>
        <Alert elevation={6} variant="filled" onClose={() => setSnackbarData(snackbarinit)} severity={snackbarData.type} sx={{ width: '100%' }}>
          {snackbarData.message}
        </Alert >
      </Snackbar>
      <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => setOpenDrawer(true)}>
        New Shippment
      </Button>
      <Drawer
        anchor={'right'}
        open={openDrawer}
        onClose={cancel}

      >
        <Box width={"50vw"}>
          <Box padding={4} >
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant='h4'>Create new shipment</Typography>
              <Box sx={{ width: '300px' }} />
              <IconButton disabled={loading} aria-label="back" onClick={cancel}>
                <Iconify icon="eva:close-outline" />
              </IconButton>
            </Box>
            <Box paddingY={4}>
              <form>
                <div>
                  <Box paddingBottom={2}>
                    <Box paddingBottom={2}>
                      <Typography variant='h5'>General Informations</Typography>
                    </Box>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12} sm={12}>
                        <TextField label="Container Id" variant="outlined" fullWidth onChange={(e) => setFormData({ ...formData, containerId: e.target.value })} />
                      </Grid>
                      {/* <Grid item md={6} xs={12} sm={12}>
                        <TextField label="Name Arabic" variant="outlined" fullWidth onChange={(e) => setFormData({ ...formData, nameAR: e.target.value })} />
                      </Grid> */}
                      <Grid item md={6} xs={12} sm={12}>
                        <TextField label="Description" variant="outlined" fullWidth onChange={(e) => setFormData({ ...formData, description: e.target.value })} />
                      </Grid>
                      <Grid item md={6} xs={12} sm={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            fullWidth
                            renderInput={(props) => <TextField fullWidth {...props} />}
                            label="Estimated Time Of Depart"
                            value={formData?.estimatedTimeDepart}
                            onChange={(newValue) => {
                              setFormData({ ...formData, estimatedTimeDepart: newValue });
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item md={6} xs={12} sm={12}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DateTimePicker
                            fullWidth
                            renderInput={(props) => <TextField fullWidth {...props} />}
                            label="Estimated Time Of Arrival"
                            value={formData?.estimatedTimeArrival}
                            onChange={(newValue) => {
                              setFormData({ ...formData, estimatedTimeArrival: newValue });
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item md={6} xs={12} sm={12}>
                        <Autocomplete
                          disablePortal
                          id="Device"
                          options={deviceList}
                          fullWidth
                          getOptionLabel={(option) => option.SerialNumber}
                          // sx={{ width: 600 }}
                          onChange={(event, newValue) => {
                            setFormData({ ...formData, deviceSerial: newValue?.SerialNumber });
                          }}
                          renderInput={(params) => <TextField {...params} label="Select Device"
                          />}
                        />
                      </Grid>
                      <Grid item md={6} xs={12} sm={12}>
                        <Autocomplete
                          disablePortal
                          id="Priority"
                          options={priorityList}
                          fullWidth
                          getOptionLabel={(option) => option.Name}
                          // sx={{ width: 600 }}
                          onChange={(event, newValue) => {
                            setFormData({ ...formData, Priority: newValue?.Id });
                          }}
                          renderInput={(params) => <TextField {...params} label="Select Priority"
                          />}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box paddingBottom={2}>
                    <Box paddingBottom={2}>
                      <Typography variant='h5'>Origin & Destination</Typography>
                    </Box>
                    <Grid container spacing={2} alignItems={'center'}>
                      {formData.origin?.label && <Grid item md={6} xs={12} sm={12}>
                        <Grid container spacing={2} marginBottom={1}>
                          <Grid item md={4} xs={12} sm={12}><b>Origin</b>: </Grid>
                          <Grid item md={8} xs={12} sm={12}>
                            <Typography variant='body2'>{formData.origin?.label}</Typography>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item md={4} xs={12} sm={12}><b>Destination</b>:</Grid>
                          <Grid item md={8} xs={12} sm={12}><Typography variant='body2'>{formData?.destination?.label}</Typography></Grid>
                        </Grid>
                        <Box paddingTop={1}>
                          <Grid container spacing={2}>
                            <Grid item md={4} xs={12} sm={12}><b>Distance</b>:</Grid>
                            <Grid item md={8} xs={12} sm={12}><Typography variant='body2'>{formData?.Distance}</Typography></Grid>
                          </Grid>
                        </Box>
                        <Box paddingTop={1}>
                          <Grid container spacing={2}>
                            <Grid item md={4} xs={12} sm={12}><b>Duartion</b>:</Grid>
                            <Grid item md={8} xs={12} sm={12}><Typography variant='body2'>{formData?.Duartion}</Typography></Grid>
                          </Grid>
                        </Box>
                      </Grid>}
                      <Grid item md={6} xs={12} sm={12}>
                        <Button variant='outlined' color='secondary' fullWidth onClick={() => setOpenMap(true)}>
                          {formData.origin?.label ? 'Change Origin & Destination' : 'Select Origin & Destination'}
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  {/* <Box paddingBottom={2}>
                  <Box paddingBottom={2}>
                    <Typography variant='h5'>Destination</Typography>
                  </Box>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <Button variant='outlined' color='secondary' fullWidth onClick={() => setOpenMap('destination')}>Select Destination</Button>
                    </Grid>
                  </Grid>
                </Box> */}
                  <Box paddingY={2}>
                    <Typography variant='h5'>Geofence</Typography>
                  </Box>
                  <Box paddingBottom={2}>
                    <Grid container spacing={2}>
                      <Grid item md={6} xs={12} sm={12}>
                        <Autocomplete
                          disablePortal
                          id="geofence"
                          options={geofenceList}
                          getOptionLabel={(option) => option.GeofenceName}
                          // sx={{ width: 300 }}
                          fullWidth
                          onChange={(event, newValue) => {
                            setFormData({ ...formData, geofenceId: newValue?.GeofenceId });
                          }}
                          renderInput={(params) => <TextField {...params} label="Select geofence" />}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {/* <Box paddingY={2}>
                  <Typography variant='h5'>Settings</Typography>
                </Box>
                <Box paddingBottom={2}>
                  <Box paddingBottom={1}><Typography variant='h6'>Tracking Mode:</Typography></Box>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <Box>
                        <FormGroup>
                          <FormControlLabel control={<Checkbox checked={formData?.settings?.tracking?.mode?.motion === 1} />} onChange={(e) => {
                            setFormData({
                              ...formData,
                              settings: {
                                ...formData?.settings,
                                tracking: {
                                  mode: {
                                    motion: e.target.checked ? 1 : 0,
                                    idle: formData?.settings?.tracking?.mode?.idle
                                  }
                                }
                              }
                            })
                          }} label="Motion" />
                        </FormGroup>
                      </Box>
                    </Grid>
                    <Grid item md={6} xs={12} sm={12}>
                      <Box>
                        <FormGroup>
                          <FormControlLabel control={<Checkbox checked={formData?.settings?.tracking?.mode?.idle === 1} />} onChange={(e) => {
                            setFormData({
                              ...formData,
                              settings: {
                                ...formData?.settings,
                                tracking: {
                                  mode: {
                                    idle: e.target.checked ? 1 : 0,
                                    motion: formData?.settings?.tracking?.mode?.motion
                                  }
                                }
                              }
                            })
                          }} label="Idle" />
                        </FormGroup>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box paddingBottom={2}>
                  <Box paddingBottom={1}> <Typography variant='h6'>Notifications:</Typography></Box>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} sm={12}>
                      <Box>
                        <FormGroup>
                          <FormControlLabel control={<Checkbox checked={formData?.settings?.notifications?.emails?.enabled} />} onChange={(e) => {
                            setFormData({
                              ...formData,
                              settings: {
                                ...formData?.settings,
                                notifications: {
                                  emails: {
                                    enabled: e.target.checked,
                                  }
                                }
                              }
                            })
                          }} label="Email" />
                        </FormGroup>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box paddingBottom={2}>
                  <Box paddingBottom={1}> <Typography variant='h6'>Alarms:</Typography></Box>
                  <Box paddingBottom={1}>
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12} sm={12}>
                        <Box>
                          <FormGroup>
                            <FormControlLabel control={<Checkbox checked={formData?.settings?.alarms?.temperature?.enabled} />}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  settings: {
                                    ...formData?.settings,
                                    alarms: {
                                      ...formData?.settings.alarms,
                                      temperature: {
                                        ...formData?.settings?.alarms?.temperature,
                                        enabled: e.target.checked,
                                      }
                                    }
                                  }
                                })
                              }} label="Temperature" />
                          </FormGroup>
                        </Box>
                      </Grid>
                      {formData?.settings?.alarms?.temperature?.enabled &&
                        <> <Grid item md={6} sm={12} xs={12}>
                          <TextField id="outlined-basic"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                settings: {
                                  ...formData?.settings,
                                  alarms: {
                                    ...formData?.settings.alarms,
                                    temperature: {
                                      ...formData?.settings?.alarms?.temperature,
                                      min: Number(e.target.value),
                                    }
                                  }
                                }
                              })
                            }}
                            label="Min temperature" variant="outlined" type={'number'} fullWidth />
                        </Grid>
                          <Grid item md={6} sm={12} xs={12}>
                            <TextField id="outlined-basic"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  settings: {
                                    ...formData?.settings,
                                    alarms: {
                                      ...formData?.settings.alarms,
                                      temperature: {
                                        ...formData?.settings?.alarms?.temperature,
                                        max: Number(e.target.value),
                                      }
                                    }
                                  }
                                })
                              }}
                              label="Max temperature" variant="outlined" type={'number'} fullWidth />
                          </Grid>
                        </>}
                    </Grid>
                  </Box>
                  <Box paddingBottom={1}>
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12} sm={12}>
                        <Box>
                          <FormGroup>
                            <FormControlLabel control={<Checkbox checked={formData?.settings?.alarms?.idle?.enabled} />}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  settings: {
                                    ...formData?.settings,
                                    alarms: {
                                      ...formData?.settings.alarms,
                                      idle: {
                                        ...formData?.settings.alarms.idle,
                                        enabled: e.target.checked,
                                      }
                                    }
                                  }
                                })
                              }} label="Idle" />
                          </FormGroup>
                        </Box>
                      </Grid>
                      {formData?.settings?.alarms?.idle?.enabled && <> <Grid item md={6} sm={12} xs={12}>
                        <TextField id="outlined-basic"
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              settings: {
                                ...formData?.settings,
                                alarms: {
                                  ...formData?.settings.alarms,
                                  idle: {
                                    ...formData?.settings.alarms.idle,
                                    time: Number(e.target.value),
                                  }
                                }
                              }
                            })
                          }}
                          label="Time" variant="outlined" type={'number'} fullWidth />
                      </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <TextField id="outlined-basic"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                settings: {
                                  ...formData?.settings,
                                  alarms: {
                                    ...formData?.settings.alarms,
                                    idle: {
                                      ...formData?.settings.alarms.idle,
                                      distance: Number(e.target.value),
                                    }
                                  }
                                }
                              })
                            }}
                            label="Distance" variant="outlined" type={'number'} fullWidth />
                        </Grid></>}
                    </Grid>
                  </Box>
                  <Box paddingBottom={1}>
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12} sm={12}>
                        <Box>
                          <FormGroup>
                            <FormControlLabel control={<Checkbox checked={formData?.settings?.alarms?.battery?.enabled} />}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  settings: {
                                    ...formData?.settings,
                                    alarms: {
                                      ...formData?.settings.alarms,
                                      battery: {
                                        ...formData?.settings.alarms.battery,
                                        enabled: e.target.checked,
                                      }
                                    }
                                  }
                                })
                              }} label="Battery" />
                          </FormGroup>
                        </Box>
                      </Grid>
                      {formData?.settings?.alarms?.battery?.enabled && <Grid item md={6} sm={12} xs={12}>
                        <TextField id="outlined-basic" onChange={(e) => {
                          setFormData({
                            ...formData,
                            settings: {
                              ...formData?.settings,
                              alarms: {
                                ...formData?.settings.alarms,
                                battery: {
                                  ...formData?.settings.alarms.battery,
                                  percentage: Number(e.target.value),
                                }
                              }
                            }
                          })
                        }} label="Percentage" variant="outlined" type={'number'} fullWidth />
                      </Grid>}
                    </Grid>
                  </Box>
                  <Box paddingBottom={1}>
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12} sm={12}>
                        <Box>
                          <FormGroup>
                            <FormControlLabel control={<Checkbox checked={formData?.settings?.alarms?.arrival?.enabled} />}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  settings: {
                                    ...formData?.settings,
                                    alarms: {
                                      ...formData?.settings.alarms,
                                      arrival: {
                                        ...formData?.settings.alarms.arrival,
                                        enabled: e.target.checked,
                                      }
                                    }
                                  }
                                })
                              }} label="Arrival" />
                          </FormGroup>
                        </Box>
                      </Grid>
                      {formData?.settings?.alarms?.arrival?.enabled && <Grid item md={6} sm={12} xs={12}>
                        <TextField id="outlined-basic" onChange={(e) => {
                          setFormData({
                            ...formData,
                            settings: {
                              ...formData?.settings,
                              alarms: {
                                ...formData?.settings.alarms,
                                arrival: {
                                  ...formData?.settings.alarms.arrival,
                                  meters: Number(e.target.value),
                                }
                              }
                            }
                          })
                        }} label="Meters" variant="outlined" type={'number'} fullWidth />
                      </Grid>}
                    </Grid>
                  </Box>
                  <Box paddingBottom={1}>
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12} sm={12}>
                        <Box>
                          <FormGroup>
                            <FormControlLabel control={<Checkbox checked={formData?.settings?.alarms?.light?.enabled} />}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  settings: {
                                    ...formData?.settings,
                                    alarms: {
                                      ...formData?.settings.alarms,
                                      light: {
                                        enabled: e.target.checked,
                                      }
                                    }
                                  }
                                })
                              }} label="Light" />
                          </FormGroup>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box paddingBottom={1}>
                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12} sm={12}>
                        <Box>
                          <FormGroup>
                            <FormControlLabel control={<Checkbox checked={formData?.settings?.alarms?.vessel?.enabled} />}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  settings: {
                                    ...formData?.settings,
                                    alarms: {
                                      ...formData?.settings.alarms,
                                      vessel: {
                                        enabled: e.target.checked,
                                      }
                                    }
                                  }
                                })
                              }} label="Vessel" />
                          </FormGroup>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box> */}
                </div>
                <Box display={'flex'} alignItems={'center'}>
                  <Button variant='contained' disabled={!enableButton || loading} onClick={saveAction}> {loading ? 'Saving...' : 'Save'}</Button>
                  <Box width={'16px'} />
                  <Button variant='outlined' onClick={cancel}>Cancel</Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Drawer>
      <Drawer
        anchor={'right'}
        open={openMap}
        onClose={() => setOpenMap('')}
      >
        <div style={{ width: '50vw', height: '100vh' }}>
          <ChakraProvider theme={theme}>
            <SelectionMap setOpenMap={setOpenMap} setFormData={setFormData} formData={formData} />
          </ChakraProvider>
        </div>
      </Drawer >
    </div >
  )
}
