import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { LoginForm } from '../sections/auth/login';
import AuthSocial from '../sections/auth/AuthSocial';
import logoWhite from '../assets/img/logo-white.svg'
import video from '../assets/video.mp4'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
          <video autoPlay muted loop id="myVideo" className='bgvideo'>
            <source src={video} type="video/mp4" />
          </video>

          {/* <HeaderStyle>
          <span style={{ marginLeft: "-16px" }}>
            <Logo size={"150px"} />
          </span>


        </HeaderStyle> */}

          {/* {mdUp && (
          <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={intro} alt="login" />
          </SectionStyle>
        )} */}
          <div className='videoOverlay' />
          <Container maxWidth="sm" style={{ zIndex: '99' }}>
            <div style={{ backgroundColor: "rgba(0, 0, 0, .7)" }}>
              <ContentStyle>
                <div className='loginImageContainer'>
                  <img src={logoWhite} alt="logoWhite" />
                </div>
                {/* <AuthSocial /> */}
                <LoginForm />

                {/* {!smUp && (
              <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                Don’t have an account?{' '}
                <Link variant="subtitle2" component={RouterLink} to="/register">
                  Get started
                </Link>
              </Typography>
            )} */}
              </ContentStyle>
            </div>
          </Container>
        </div>
      </RootStyle>
    </Page>
  );
}
