import React, { useState, useRef, useEffect } from 'react';
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
  InfoWindow,
  Polyline
} from '@react-google-maps/api'
import navigation from '../assets/img/navigation-2.svg'
import locationMarker from '../assets/img/Location.svg'
import containerMarker from '../assets/img/container-marker.svg'
import startIcon from '../assets/img/startIcon.svg'
import OBJECTS from '../_mock/objects';
import { waypoints } from '../config';

const CustomMarker = () => {
  return (<img alt='marker' src={navigation} />)
}

const center = { lat: 24.7136, lng: 46.6753 }
const start = { lat: 24.691704, lng: 46.665965 };
const end = { lat: 24.7779528, lng: 46.5777556 }

function CustomMap({ selected,
  showHistory,
  historyData, setActiveIndex,
  selectedList,
  showCenter,
  getHistoryAction,
  id,
  Name,
  setShowHistory,
  setIsOpen
}) {
  const [libraries, setLibraries] = useState(['places']);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyBPVP4bF84FZ_qZkqhWTdpMO4_2kGv-Tl4',
    // googleMapsApiKey: 'AIzaSyCnhDNnG9WqjXYoiRYRbGIedF6PKEiBFRg',
    libraries
  })
  const [map, setMap] = useState(/** @type google.maps.Map */(null))
  const [directionsResponse, setDirectionsResponse] = useState(null)
  // const [distance, setDistance] = useState('');
  const [showInfo, setShowInfo] = useState('');
  const [duration, setDuration] = useState('');
  const [showItem, setShowItem] = useState(0);
  const [way, setWay] = useState([]);
  const [firstItem, setShowFirstItem] = useState('');
  const [hiistoryMarkerPoints, setHistoryMarkerPoints] = useState([]);
  const [progress, setProgress] = useState([]);
  const google = window.google;
  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef()
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef()
  // async function calculateRoute() {
  // 	// 	if (originRef.current.value === '' || destiantionRef.current.value === '') {
  // 	// 		return
  // 	// 	}
  // 	// eslint-disable-next-line no-undef

  // 	const directionsService = new google.maps.DirectionsService();
  // 	const request = {
  // 		origin: start,
  // 		destination: end,
  // 		travelMode: 'DRIVING',
  // 		waypoints: way.map((l) => ({ stopover: true, position: l.position }))
  // 	};
  // 	// const results = await directionsService.route({
  // 	// 	origin: start,
  // 	// 	destination: end,
  // 	// 	// eslint-disable-next-line no-undef
  // 	// 	travelMode: google.maps.TravelMode.DRIVING,
  // 	// })
  // 	directionsService.route(request, (result, status) => {
  // 		// console.log('results', result)
  // 		if (status === 'OK') {
  // 			// directionsRenderer.setDirections(result);
  // 			setDirectionsResponse(result)
  // 			// setDistance(results.routes[0].legs[0].distance.text)
  // 			// setDuration(results.routes[0].legs[0].duration.text)
  // 		}
  // 	});
  // }

  async function calculateRoute() {
    // if (originRef.current.value === '' || destiantionRef.current.value === '') {
    // 	return
    // }
    // eslint-disable-next-line no-undef
    const origin = { lat: 6.5244, lng: 3.3792 };
    const destination = { lat: 6.4667, lng: 3.4500 };
    const directionsService = new google.maps.DirectionsService()
    const list = historyData.filter(l => (l.localization?.location !== undefined || l.localization?.location !== null) && l.type === "CLOSE" && l.temperature >= 30)
    const results = await directionsService.route({
      origin: historyData[historyData.length - 1]?.localization?.location,
      destination: historyData[0]?.localization?.location,
      // origin: origin,
      // destination: destination,
      // eslint-disable-next-line no-undef
      waypoints: list.map((item) => ({ location: new google.maps.LatLng(item.localization?.location.lat, item.localization?.location.lng) })),
      // waypoints: [
      // 	{
      // 		location: new google.maps.LatLng(6.4698, 3.5852)
      // 	},
      // 	{
      // 		location: new google.maps.LatLng(6.6018, 3.3515)
      // 	}
      // ],
      travelMode: google.maps.TravelMode.DRIVING,
    })
    console.log("results", results)
    setDirectionsResponse(results)
  }

  // function clearRoute() {
  // 	setDirectionsResponse(null)
  // 	setDistance('')
  // 	setDuration('')
  // 	originRef.current.value = ''
  // 	destiantionRef.current.value = ''
  // }
  React.useEffect(() => {
    if (google && historyData.length > 0) {
      calculateRoute();
    }
    // }, [google, hiistoryMarkerPoints, historyData])
  }, [google, historyData])

  console.log("historyData", historyData)


  const velocity = 100;
  const initialDate = new Date();

  const getDistance = () => {
    // seconds between when the component loaded and now
    const differentInTime = (new Date() - initialDate) / 1000; // pass to seconds
    return differentInTime * velocity; // d = v*t -- thanks Newton!
  };

  const moveObject = () => {
    const distance = getDistance();
    console.log("distance", distance)
    if (!distance) {
      return;
    }

    let progress = waypoints.filter(
      coordinates => coordinates.distance < distance
    );
    console.log("progress", progress)
    const nextLine = waypoints.find(
      coordinates => coordinates.distance > distance
    );
    if (!nextLine) {
      setProgress(progress);
      return;
    }

    const lastLine = progress[progress.length - 1];

    const lastLineLatLng = window.google?.maps.LatLng(
      lastLine.lat,
      lastLine.lng
    );

    const nextLineLatLng = window.google?.maps.LatLng(
      nextLine.lat,
      nextLine.lng
    );

    // distance of this line
    const totalDistance = nextLine.distance - lastLine.distance;
    const percentage = (distance - lastLine.distance) / totalDistance;

    const position = window.google.maps.geometry.spherical.interpolate(
      lastLineLatLng,
      nextLineLatLng,
      percentage
    );

    progress = progress.concat(position);
    setProgress(progress);
  }

  // React.useEffect(() => {

  // 	if (historyData.length > 0) {
  // 		const timerId = setInterval(
  // 			() => setShowItem((i) => (i + 1) % historyData.length), // <-- increment index
  // 			5000
  // 		);
  // 		return () => clearInterval(timerId);
  // 	}
  // }, [historyData, showItem]);

  // React.useEffect(() => {
  // 	if (historyData.length > 0) {
  // 		setHistoryMarkerPoints([historyData[showItem]]); // <-- update media state when index updates
  // 		setActiveIndex(showItem)
  // 	}
  // }, [showItem]);

  useEffect(() => {
    // if (!isLoaded) {
    // 	return;
    // }

    // console.log("window.google?.maps", google?.maps)
    // const path = waypoints.map((coordinates, i, array) => {
    // 	if (i === 0) {
    // 		return { ...coordinates, distance: 0 }; // it begins here!
    // 	}
    // 	const { lat: lat1, lng: lng1 } = coordinates;
    // 	const latLong1 = google?.maps.LatLng.toString(lat1, lng1);

    // 	const { lat: lat2, lng: lng2 } = array[0];
    // 	const latLong2 = google?.maps.LatLng.toString(lat2, lng2);

    // 	// in meters:
    // 	const distanceMatrix = new google.maps.DistanceMatrixService();
    // 	console.log("latLong1", latLong1)
    // 	const request = {
    // 		origins: [latLong1],
    // 		destinations: [latLong2],
    // 		travelMode: 'DRIVING',
    // 	};
    // 	const distance = distanceMatrix.getDistanceMatrix(request)
    // 		.then(res => console.log('resss', res)).catch(err => console.log('errr', err))
    // 	console.log("distance", distance)

    // 	return { ...coordinates, distance };
    // });

    // console.log("path", path);
    // const interval = setInterval(() => {
    // 	// historyData.map((item) => setHistoryMarkerPoints([item]))
    // 	setHistoryMarkerPoints(historyData)
    // 	// if (historyData.length > 0) {
    // 	// 	// setShowFirstItem(historyData[0]?.position)
    // 	// 	setHistoryMarkerPoints([historyData[0]]);
    // 	// 	// setShowItem(1);
    // 	// 	const item = {}
    // 	// 	// setWay([historyData[0]])
    // 	// } else if (historyData.length > 1) {
    // 	// 	setHistoryMarkerPoints([historyData[1]])
    // 	// 	// setShowItem(2)
    // 	// 	// setWay(...way, [historyData[1]])
    // 	// } else if (historyData.length > 2) {
    // 	// 	setHistoryMarkerPoints([historyData[2]])
    // 	// 	// setShowItem(3)
    // 	// 	// setWay(...way, [historyData[2]])
    // 	// }
    // 	// if (showItem === 3) {
    // 	// 	clearInterval(interval);
    // 	// }
    // 	// moveObject()
    // 	historyData.map((item, index) => setShowItem(index))
    // }, 5000);
    // return () => clearInterval(interval);

  }, [historyData, google, isLoaded])

  // useEffect(() => {
  // 	const lastIndex = historyData.length - 1
  // 	if (showItem < 0) {
  // 		setShowItem(lastIndex)
  // 	}
  // 	if (showItem > lastIndex) {
  // 		setShowItem(0)
  // 	}
  // }, [showItem, historyData])

  // useEffect(() => {
  // 	const interval = setInterval(() => {
  // 		setShowItem(showItem + 1)
  // 	}, 5000);
  // 	return () => clearInterval(interval)
  // }, [showItem])


  console.log('hiistoryMarkerPoints', hiistoryMarkerPoints)

  const center = showCenter || historyData[0]?.localization?.location || { lat: 25.3548, lng: 51.1839 }
  const list = historyData.filter(l => l.localization?.location !== undefined)
  const lineSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 1,
    scale: 4,
  };
  return (
    <>
      {!isLoaded ? 'Loading...' : <GoogleMap
        center={showHistory ? center : selectedList[0]?.Event?.localization?.location}
        // center={{ lat: 25.3548, lng: 51.1839 }}
        zoom={showCenter ? 12 : 10}
        mapContainerStyle={{ width: '100%', height: '100%' }}
        options={{
          // zoomControl: false,
          streetViewControl: false,
          // mapTypeControl: false,
          fullscreenControl: false,
          // mapTypeId: 'ROADMAP'
        }}
        onLoad={map => setMap(map)}
      >
        {showHistory
          ? <>
            {/* {directionsResponse && (
							<DirectionsRenderer directions={directionsResponse} />
						)} */}
            <Polyline
              path={list.map(l => l.localization?.location)}
              options={{
                strokeColor: "#FF0000 ", strokeOpacity: 0, icons: [
                  {
                    icon: lineSymbol,
                    offset: "0",
                    repeat: "20px",
                  },
                ],
              }}
            />
            {list.map((item, index) => (
              <Marker
                // onClick={() => { setShowHistory(Name); getHistoryAction(id) }}
                icon={{
                  url: index === list.length - 1 ? startIcon : containerMarker,
                  // anchor: new google.maps.Point(50, 50),
                  // scaledSize: new google.maps.Size(20, 20)
                }}
                // position={progress[progress.length - 1]}
                position={item.localization?.location}
                key={index}
              />
            ))}
            {/* {hiistoryMarkerPoints.map((item, index) => (
							<Marker
								className="marker"
								onMouseOver={() => setShowInfo(item.id)}
								onMouseOut={() => setShowInfo('')}
								key={item.id}
								icon={{
									url: navigation,
									// anchor: new google.maps.Point(50, 50),
									scaledSize: new google.maps.Size(20, 20)
								}}
								position={item.position}>
								{showInfo === item.id && <InfoWindow >
									<>
										{item.name} {item.arabicName}
										<br />
										<span>{item.speed} km/h</span>
									</>
								</InfoWindow>}
							</Marker>
						))} */}
          </>
          : <>
            {selectedList.map((item, index) => (
              <Marker
                onMouseOver={() => setShowInfo(item.Id)}
                onMouseOut={() => setShowInfo('')}
                onClick={() => { setShowHistory(item.Name || item.ContainerId); getHistoryAction(item.DeviceSerial); setShowInfo('') }}
                key={item.Id}
                icon={{
                  url: containerMarker,
                  // anchor: new google.maps.Point(50, 50),
                  scaledSize: new google.maps.Size(40, 40)
                }}
                position={item?.Event?.localization?.location}>
                {showInfo === item.Id && <InfoWindow >
                  <>
                    {item.Name} {item.ArabicName || item.ContainerId}
                    <br />
                    <span>{item.speed || 0} km/h</span>
                  </>
                </InfoWindow>}
              </Marker>
            ))}
          </>}
      </GoogleMap>}

    </>
  )
}

export default CustomMap