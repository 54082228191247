import { faker } from '@faker-js/faker';
import { sample } from 'lodash';

const objects = [
  { name: '3501', position: { lat: 24.691704, lng: 46.665965, }, speed: '0', id: faker.datatype.uuid(), arabicName: 'ب د هـ', },
  { name: '3502', position: { lat: 24.7779528, lng: 46.5777556, }, speed: '0', id: faker.datatype.uuid(), arabicName: 'ب د هـ', },
  { name: '3503', position: { lat: 24.7580499, lng: 46.5269193, }, speed: '0', id: faker.datatype.uuid(), arabicName: 'ب د هـ', },
  { name: '3504', position: { lat: 24.807455, lng: 46.5122422, }, speed: '0', id: faker.datatype.uuid(), arabicName: 'ب د هـ', },
  { name: '3505', position: { lat: 24.9850304, lng: 46.6650027, }, speed: '0', id: faker.datatype.uuid(), arabicName: 'ب د هـ', },
  { name: '3506', position: { lat: 25.0522342, lng: 46.5055603, }, speed: '0', id: faker.datatype.uuid(), arabicName: 'ب د هـ', },
  { name: '3507', position: { lat: 24.589646, lng: 46.7382985, }, speed: '0', id: faker.datatype.uuid(), arabicName: 'ب د هـ', },
  { name: '3508', position: { lat: 24.536237, lng: 46.5937492, }, speed: '0', id: faker.datatype.uuid(), arabicName: 'ب د هـ', },
  { name: '3509', position: { lat: 24.5292739, lng: 46.4564774, }, speed: '0', id: faker.datatype.uuid(), arabicName: 'ب د هـ', },
  { name: '35010', position: { lat: 24.831716, lng: 46.7505847, }, speed: '0', id: faker.datatype.uuid(), arabicName: 'ب د هـ', },
]

export default objects;
