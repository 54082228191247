// component
import Iconify from '../../components/Iconify';
import Location from '../../assets/img/location-update.svg'
// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon('eva:pie-chart-2-outline'),
  // },
  // {
  //   title: 'Configuartion',
  //   path: '/dashboard/configuartion',
  //   icon: getIcon('eva:settings-outline'),
  // },
  {
    title: 'Tracking',
    path: '/dashboard/tracking',
    icon: getIcon('eva:navigation-2-outline'),
  },

  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  {
    title: 'Reports',
    path: '/dashboard/reports',
    icon: getIcon('eva:book-outline'),
  },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: getIcon('eva:file-text-fill'),
  // },
  {
    title: 'Violations',
    path: '/violations',
    icon: getIcon('eva:bell-outline'),
  },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
  {
    title: 'Geofence',
    path: '/dashboard/geofence',
    icon: getIcon('eva:radio-button-on-outline'),
  },
];

export default navConfig;
